import React from 'react'
import {Link} from 'gatsby'

export default ({ post }) => {
  return (

    <Link to={post.fields.slug} style={{
      textDecoration: 'none',
      color: '#fff',
    }}>
      <div style={{
        background: '#1d0f0f',
        border: '1px solid #381010',
        padding: '30px',
        height: '100%',
        marginRight: 20,
        borderRadius: 12
      }}>
        <small style={{fontSize: '.7rem', opacity: .5}}>{post.frontmatter.date}</small>

        <h3 style={{
          fontSize: '.9rem',
          marginTop: 6
        }}>{post.frontmatter.title}</h3>

        <p style={{fontSize: '.8rem', opacity: .7}}>{post.excerpt}</p>
      </div>
    </Link>

  )

}
