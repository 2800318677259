import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import SlimHeader from './slim-header'
import '../style/links.css'

const BlogLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryBlog {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div style={{
          background: '#fefffc',
          minHeight: '100vh'
        }}>
          <SlimHeader siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1280,
              padding: `50px 1.0875rem 1.45rem`,
              paddingTop: 0,
            }}
          >
            {children}
          </div>
        </div>
      </>
    )}
  />
)

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogLayout
