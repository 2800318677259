import React from 'react'
import { graphql } from "gatsby"
import BlogLayout from "../components/layout-blog"
import SEO from '../components/seo'

import PostCard from '../components/post-card'

export default ({ data }) => {
  const post = data.post
  return (
    <div>
      <BlogLayout>
        <SEO title={post.frontmatter.title} keywords={post.frontmatter.tags} />
        <div>
          <div style={{
            marginBottom: 30
          }}>
            <h1 style={{
              marginBottom: 0
            }}>{post.frontmatter.title}</h1>
            <small>{post.frontmatter.category.charAt(0).toUpperCase() + post.frontmatter.category.slice(1)} - {post.frontmatter.date}</small>
          </div>

          <div className="post" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>

      </BlogLayout>

      <div style={{
        background: '#140000',
        color: '#fff',
        padding: '50px 100px',

      }}>
        <h3>Related posts</h3>

        <div style={{
          display: 'flex',
        }}>
          {data.categoryPosts.edges.map(({ node }) => (
            <div key={node.frontmatter.title} style={{
              maxWidth: 370
            }}>

              <PostCard post={node} />

            </div>
          ))}
        </div>
      </div>

    </div>
  )
}

export const query = graphql`
  query($slug: String! $category: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title,
        date(formatString: "MMMM DD, YYYY"),
        category
      }
    }
    categoryPosts: allMarkdownRemark(
      limit: 4
      filter: { frontmatter: { category: { eq: $category } published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 80)
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            category
          }
        }
      }
    }
  }

`
