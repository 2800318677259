import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <div
    style={{
      padding: '25px 20px',
      background: 'linear-gradient(to right, #f00000, #dc281e)',
      marginBottom: 70
    }}
  >
      <h1 style={{
        margin: 0,
        fontWeight: '300',
        fontSize: '1.2rem'
      }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,

          }}
        >
          allabout<span style={{
            color: 'white'
          }}>.school</span>
        </Link>
      </h1>

  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
